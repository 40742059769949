/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {ProductBox} from '../../components/boxen';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    h2: "h2",
    code: "code",
    pre: "pre",
    h3: "h3",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", React.createElement(Card, {
    sx: {
      paddingLeft: '1em',
      paddingBottom: '1em'
    }
  }, React.createElement(Box, {
    display: "flex",
    alignItems: "baseline"
  }, React.createElement(Box, null, React.createElement(_components.h1, null, "Gatsby")), React.createElement(Box, {
    ml: 2
  }, React.createElement(_components.p, null, "Modern serverless web applications using react"))), React.createElement(_components.p, null, "Gatsbyjs is a ", React.createElement(_components.a, {
    href: "jamstack.org"
  }, "Jamstack"), " implementation that can\nuse AWS Amplify hosting directly.  (see our ", React.createElement(_components.a, {
    href: "/blog/amplify"
  }, "Amplify"), " page for\nmore information on install and integration with AWS Amplify)"), React.createElement(_components.p, null, "Note: You can also host Gatsby projects directly on AWS S3 and Cloudfront,\ndescribed well ", React.createElement(_components.a, {
    href: "https://blog.joshwalsh.me/aws-gatsby/"
  }, "here on Joshua Walsh's Blog"), ".\n(ignore the title, I like my way better)"), React.createElement(_components.p, null, "Running Gatsby with AWS Amplify is very inexpensive, and integrates well with\npublic Git providers to provide a slick development pipeline and easy\naccess to a wide variety of AWS services.  The final application uses a CDN\nto scale and runs very efficiently, it is hard to beat on general lighthouse\nperformance tests."), React.createElement(Box, {
    width: "75%",
    display: "flex",
    gap: "20px",
    justifyContent: "center"
  }, React.createElement(ProductBox, {
    maintitle: "Content"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#notable-upgrades"
  }, "notable upgrades")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#gatsby-image"
  }, "gatsby image")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#gatsby-and-typescript"
  }, "gatsby and typescript")), "\n", React.createElement(_components.li, null, "gatsby link vs mui link"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#gatsby-loves-shopify"
  }, "gatsby loves shopify")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#raw-notes"
  }, "raw notes")), "\n")), React.createElement(ProductBox, {
    maintitle: "Resources"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/amplify"
  }, "WD amplify guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/amplify-ui"
  }, "WD amplify-ui guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gatsbyjs.com"
  }, "Gatsby official site"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/"
  }, "docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/blog/"
  }, "blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/"
  }, "release notes")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby"
  }, "github")), "\n")))), "\n", "\n", React.createElement("a", {
    name: "notable-upgrades"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "Notable upgrades"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/"
  }, "Gatsby Official release notes")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "v5 ", React.createElement(_components.a, {
    href: "#gatsby-v5"
  }, "migration notes"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "5.2 removes svgTrace on images (license issues)"), "\n", React.createElement(_components.li, null, "5.1 fixes graphql issue"), "\n", React.createElement(_components.li, null, "5.0 couldn't install"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "v4 ", React.createElement(_components.a, {
    href: "#gatsby-v4"
  }, "migration notes"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "4.24 requires gatsby-cli update to 4.24"), "\n", React.createElement(_components.li, null, "4.23 slice"), "\n", React.createElement(_components.li, null, "4.22 deprecated gatsby-source-graphql"), "\n", React.createElement(_components.li, null, "4.21 ", React.createElement(_components.a, {
    href: "/blog/markdown#upgrades-v2"
  }, "markdown v2.0")), "\n", React.createElement(_components.li, null, "4.20 unusable in development mode"), "\n", React.createElement(_components.li, null, "4.19 gatsby head introduction, +todo:replaces react-helmet+"), "\n", React.createElement(_components.li, null, "4.16 lighthouse notices lost source-map (not compatible with react 18?)"), "\n", React.createElement(_components.li, null, "4.15 new graphql typegen features, and new non-blocking script tag"), "\n", React.createElement(_components.li, null, "4.13 pwa fixes for offline worker on react18"), "\n", React.createElement(_components.li, null, "4.11 +shopify plugin v7+"), "\n", React.createElement(_components.li, null, "4.9 typescript +gatsby-config, gatsby-node+ support"), "\n", React.createElement(_components.li, null, "4.8 typescript +gatsby-browser, gatsby-ssr+ support"), "\n", React.createElement(_components.li, null, "requires node 14, begins support for node 18"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "v3"), "\n")), "\n", "\n", React.createElement("a", {
    name: "gatsby-and-typescript"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "Typescript"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript"
  }, "ts gatsby doc")), React.createElement(_components.p, null, "Additional info in the release notes for"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/v4.9/"
  }, "4.9"), " gatsby-config, gatsby-node"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/v4.8/"
  }, "4.8"), " gatsby-browser, gatsby-ssr"), "\n")), "\n", "\n", React.createElement("a", {
    name: "gatsby-loves-shopify"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "Gatsby Loves Shopify"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/building-an-ecommerce-site-with-shopify/"
  }, "plugin"), " ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby-starter-shopify/blob/main/gatsby-config.js"
  }, "gatsby starter"))), "\n", "\n", React.createElement("a", {
    name: "gatsby-query-params"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "gatsby-query-params"), React.createElement(_components.p, null, "This is deprecated in gatsby v4, but gets bad when using npm9"), React.createElement(_components.p, null, "The fix is to use the new hook use-query-params"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "npm install use-query-params gatsby-plugin-use-query-params"), "\n")), "\n", "\n", React.createElement("a", {
    name: "gatsby-v5"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "Gatsby v5"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "github ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/discussions/36609"
  }, "gatsby 5 umbrella discussion")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "upgrade to node v18"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "upgrading to node v18 (from v14.16.0) and npm v9 (from v6.14.11)"), "\n", React.createElement(_components.li, null, "this will require removal of node_modules and npm i for existing projects, along with new package-lock v2 generated."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "not updated yet, this must be removed for now"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "gatsby-plugin-material-ui@4"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v4-to-v5/"
  }, "upgrade to v5")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "npm i -g gatsby-cli@5")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "npm i gatsby@5 gatsby-plugin-image@3 gatsby-plugin-manifest@5 gatsby-plugin-mdx@5 gatsby-plugin-offline@6 gatsby-plugin-react-helmet@6 gatsby-plugin-sharp@5 gatsby-remark-images@7 gatsby-source-filesystem@5 gatsby-transformer-sharp@5")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "npm i babel-preset-gatsby@3")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "may also need"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "gatsby-plugin-sass@5"), "\n"), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v4-to-v5/#trailingslash-is-set-to-always"
  }, "trailing slash change"), "\ntrailing slash is now default always (was legacy)\nthe options are always, never, and ignore (dont change it)"), React.createElement(_components.p, null, "gatsby cloud handles all settings, but amplify ???"), React.createElement(_components.p, null, "gatsby-config.js"), React.createElement(_components.pre, null, React.createElement(_components.code, null, "module.exports = {\n  trailingSlash: `always`,\n}\n")), React.createElement(_components.h2, null, "Deprecations"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "page props ", React.createElement(_components.code, null, "const { location, params, data, pageContext, serverData } = props")), "\n"), React.createElement(_components.p, null, "Effective changes in v6 will include:"), React.createElement(_components.p, null, "Remove pageResources prop currently only available in browser context (it's an internal data structure)\nRemove * prop currently only available in server context (in favor of location prop)\nRemove path prop that currently differs in server and browser context (in favor of location prop)\nRemove uri prop in favor of location prop"), React.createElement(_components.p, null, "The location prop will remain different in the browser and server context due to the router offering more properties in the browser context from window.location"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "StaticQuery\nreplaced with ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/blob/master/docs/how-to/querying-data/use-static-query/#composing-custom-usestaticquery-hooks"
  }, "useStaticQuery hook")), "\n")), "\n", "\n", React.createElement("a", {
    name: "gatsby-v4"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "Gatsby v4"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "v4 implementation ", React.createElement(_components.a, {
    href: "#gatsby-image"
  }, "gatsby-image deprecation ")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v3-to-v4/"
  }, "upgrade to v4")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "npm i gatsby@4 gatsby-plugin-image@2 gatsby-plugin-manifest@4 gatsby-plugin-material-ui@4 gatsby-plugin-mdx@4 gatsby-plugin-react-helmet@5 gatsby-plugin-sass@5 gatsby-plugin-sharp@4 gatsby-remark-images@6 gatsby-source-filesystem@4 gatsby-transformer-sharp@4")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "npm i babel-preset-gatsby@2")), "\n"), "\n"), "\n"), React.createElement(_components.pre, null, React.createElement(_components.code, null, "`Cannot find module 'gatsby-page-utils/apply-trailing-slash-option' from 'node_modules/gatsby-link/dist/index.js'`\n\n\n error TS2322: Type 'Mock<Promise<{ data: { listChannels: { nextToken: null; items: ({ atype: string; channelid: null; chkey: null; chlink: null; chtype: null; } | { atype: string; channelid: string; chkey: string; chlink: null; chtype: string; } | { ...; })[]; }; }; }>, []>' is not assignable to type '<T>(options: GraphQLOptions, additionalHeaders?: { [key: string]: string; } | undefined) => T extends GraphQLQuery<T> ? Promise<GraphQLResult<T>> : T extends GraphQLSubscription<...> ? Observable<...> : Promise<...> | Observable<...>'.\n  Type 'Promise<{ data: { listChannels: { nextToken: null; items: ({ atype: string; channelid: null; chkey: null; chlink: null; chtype: null; } | { atype: string; channelid: string; chkey: string; chlink: null; chtype: string; } | { ...; })[]; }; }; }>' is not assignable to type 'T extends GraphQLQuery<T> ? Promise<GraphQLResult<T>> : T extends GraphQLSubscription<T> ? Observable<...> : Promise<...> | Observable<...>'.\n\n116     API.graphql = MockListChannels;\n"))), "\n", React.createElement(_components.h2, null, React.createElement("a", {
    name: "raw-notes"
  }), " Notes"), "\n", React.createElement(_components.h3, null, "install"), "\n", React.createElement("pre", null, React.createElement("code", null, React.createElement(_components.p, null, "npm i gatsby\nnpm i --dev babel-preset-gatsby"))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, React.createElement("a", {
    name: "gatsby-image"
  }), " gatsby-image"), "\n", React.createElement(_components.h3, null, "overview"), "\n", React.createElement(_components.p, null, "Not quite sure when this happened, but the gatsby-image-plugin\ncompletely overlaps the use of gatsby-image, which is now\ndeprecated."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/reference/release-notes/image-migration-guide/"
  }, "migration guide")), "\n"), "\n", React.createElement(_components.h3, null, "gatsby-config"), "\n", React.createElement(_components.p, null, "plugin requirements"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "module.exports = {\n  plugins: [\n    `gatsby-plugin-image`,\n    `gatsby-plugin-sharp`,\n    `gatsby-transformer-sharp`, // Needed for dynamic images\n  ],\n}\n")), "\n", React.createElement(_components.p, null, "use"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "import { StaticImage } from \"gatsby-plugin-image\"\nimport { GatsbyImage } from \"gatsby-plugin-image\"\nimport { getImage } from \"gatsby-plugin-image\"\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
